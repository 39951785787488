import { Box, Container } from '@mui/material';
import { Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import Loader from '@/components/Loader';
import Footer from '@/layout/Footer';
import Header from '@/layout/Header';
import nprogress from '@/utils/nprogress';

const Layout = () => {
  useEffect(() => {
    nprogress.done();
  });

  nprogress.start();
  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      position="relative"
    >
      <Header />
      <Box id="main" mb={10} py={2}>
        <Container maxWidth={false}>
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};

export default Layout;
