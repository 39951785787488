import 'dayjs/locale/fr';
import 'dayjs/locale/en';

import { CloseRounded, DoneAllRounded } from '@mui/icons-material';
import {
  Badge,
  Box,
  Divider,
  FormControlLabel,
  Stack,
  styled,
  Switch,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import NotificationBlock from './NotificationBlock';

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    right: -15,
    top: 10,
    padding: '0 4px',
  },
}));

const NotificationContent = ({ notifications }) => {
  const { t } = useTranslation();
  const [active, setActive] = useState(null);
  const notificationsUnseen = notifications?.filter((n) => n.seen === false);
  const [onlyUnseen, setOnlyUnseen] = useState(false);
  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          px: 2,
          justifyContent: 'space-between',
          alignItems: 'center',
          pb: 1,
        }}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'primary.main',
            cursor: 'pointer',
          }}
        >
          <StyledBadge
            badgeContent={notificationsUnseen.length}
            color="error"
            invisible={notificationsUnseen?.length === 0}
            max={99}
          >
            <Typography>{t('notification.activities')}</Typography>
          </StyledBadge>
        </Box>
        <Box>
          <FormControlLabel
            control={<Switch size="small" />}
            label={
              onlyUnseen ? (
                <Typography variant="body2">
                  {t('notification.unread_only')}
                </Typography>
              ) : (
                <Typography variant="body2">{t('notification.all')}</Typography>
              )
            }
            labelPlacement="start"
            onChange={() => setOnlyUnseen(!onlyUnseen)}
          />
        </Box>
      </Box>
      <Stack
        sx={{
          overflow: 'auto',
          maxHeight: 360,
          borderTop: 1,
          borderColor: 'divider',
        }}
      >
        {!onlyUnseen && notifications?.length === 0 && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              px: 2,
              py: 4,
            }}
          >
            <Box>
              <CloseRounded sx={{ fontSize: 60, color: 'warning.main' }} />
            </Box>
            <Typography variant="body2">
              {t('notification.no_activities')}
            </Typography>
          </Box>
        )}
        {!onlyUnseen &&
          notifications?.map((notification, index) => (
            <>
              <NotificationBlock
                notification={notification}
                active={active}
                setActive={setActive}
              />
              {index !== notifications.length - 1 && <Divider />}
            </>
          ))}
        {onlyUnseen && notificationsUnseen?.length === 0 && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              px: 2,
              py: 4,
            }}
          >
            <Box>
              <DoneAllRounded sx={{ fontSize: 60, color: 'primary.main' }} />
            </Box>
            <Typography variant="body2">
              {t('notification.no_unread_activities')}
            </Typography>
          </Box>
        )}
        {onlyUnseen &&
          notificationsUnseen?.map((notification, index) => (
            <>
              <NotificationBlock
                notification={notification}
                active={active}
                setActive={setActive}
              />
              {index !== notifications.length - 1 && <Divider />}
            </>
          ))}
      </Stack>
    </Box>
  );
};

NotificationContent.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape()),
};

NotificationContent.defaultProps = {
  notifications: [],
};

export default NotificationContent;
