import { useQuery } from 'react-query';

import axios from '@/utils/axios';

export const getAllDemandStatus = async () => {
  const { data } = await axios.get('/demands/status?enabled=true');
  return data;
};

export const useDemandStatus = () =>
  useQuery(['demand_status'], getAllDemandStatus);
