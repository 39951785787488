import { useQuery } from 'react-query';

import axios from '@/utils/axios';

export const getFlowcells = async () => {
  const { data } = await axios.get(`/flowcell`);
  return data;
};

export const useFlowcells = () => useQuery(['flowcell'], () => getFlowcells());
