import 'core-js/stable';
import 'regenerator-runtime/runtime';
import './utils/i18n';

import { CircularProgress } from '@mui/material';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<CircularProgress />}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);
