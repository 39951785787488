import { useMutation, useQuery, useQueryClient } from 'react-query';

import axios from '@/utils/axios';

export const getNotifications = async (userId) => {
  const { data } = await axios.get(`/notifications/?user_id=${userId}`);
  return data;
};

export const seenNotification = async (notification) => {
  const { data } = await axios.put(
    `/samples/batches/notifications/${notification.content.sample_batch_id}/${notification.content.user_id}`,
    { seen: true },
  );
  return data;
};

export const seenDemandNotification = async (notification) => {
  const { data } = await axios.put(
    `/demands/notifications/${notification.content.id}`,
    { seen: true },
  );
  return data;
};

export const seenAllNotifications = async (userId) => {
  const { data } = await axios.put(
    `/notifications/?seen=true&user_id=${userId}`,
  );

  return data;
};

export const unseenNotification = async (notification) => {
  const { data } = await axios.put(
    `/samples/batches/notifications/${notification.content.sample_batch_id}/${notification.content.user_id}`,
    { seen: false },
  );
  return data;
};

export const unseenDemandNotification = async (notification) => {
  const { data } = await axios.put(
    `/demands/notifications/${notification.content.id}`,
    { seen: false },
  );
  return data;
};

export const useSeenNotification = () => {
  const queryClient = useQueryClient();

  return useMutation((notification) => seenNotification(notification), {
    onSettled: () => {
      queryClient.invalidateQueries(['notifications']);
    },
  });
};

export const useUnSeenNotification = () => {
  const queryClient = useQueryClient();

  return useMutation((notification) => unseenNotification(notification), {
    onSettled: () => {
      queryClient.invalidateQueries(['notifications']);
    },
  });
};

export const useSeenDemandNotification = () => {
  const queryClient = useQueryClient();

  return useMutation((notification) => seenDemandNotification(notification), {
    onSettled: () => {
      queryClient.invalidateQueries(['notifications']);
    },
  });
};

export const useUnSeenDemandNotification = () => {
  const queryClient = useQueryClient();

  return useMutation((notification) => unseenDemandNotification(notification), {
    onSettled: () => {
      queryClient.invalidateQueries(['notifications']);
    },
  });
};

export const useSeenAllNotifications = () => {
  const queryClient = useQueryClient();

  return useMutation((userId) => seenAllNotifications(userId), {
    onSettled: () => {
      queryClient.invalidateQueries(['notifications']);
    },
  });
};

export const useNotifications = (userId) =>
  useQuery(['notifications', 'mine'], () => getNotifications(userId));
