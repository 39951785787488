import { Brightness2 } from '@mui/icons-material';
import { ListItem, ListItemIcon, ListItemText, Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ThemeStore from '@/theme/ThemeStore';

const ThemeSwitcher = () => {
  const { t } = useTranslation();
  const theme = ThemeStore((state) => state.theme);
  const changeTheme = ThemeStore((state) => state.changeTheme);
  return (
    <ListItem sx={{ cursor: 'pointer' }} onClick={() => changeTheme()}>
      <ListItemIcon>
        <Brightness2 />
      </ListItemIcon>
      <ListItemText primary={t(`common.theme.dark`)} />
      <Switch
        edge="end"
        checked={theme === 'dark'}
        inputProps={{
          'aria-labelledby': 'switch-list-label-bluetooth',
        }}
      />
    </ListItem>
  );
};

export default ThemeSwitcher;
