import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const FileRejectionItems = ({ fileRejections }) => (
  <List dense sx={{ maxHeight: '250px', overflow: 'auto' }}>
    {fileRejections.map(({ file, errors }) => (
      <React.Fragment key={file.path}>
        <ListItem key={file.path}>
          <ListItemIcon>
            <UploadFileIcon color="error" fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={file.path}
            secondary={errors.map((e) => (
              <Typography
                key={file.path + e.code}
                variant="overline"
                color="error"
                sx={{
                  '& + .MuiTypography-root::before': {
                    display: 'inline-block',
                    whiteSpace: 'pre',
                    content: '", "',
                  },
                }}
              >
                {e.message}
              </Typography>
            ))}
          />
        </ListItem>
        <Divider variant="inset" component="li" />
      </React.Fragment>
    ))}
  </List>
);

FileRejectionItems.propTypes = {
  fileRejections: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.shape({
        path: PropTypes.string.isRequired,
      }).isRequired,
      errors: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string.isRequired,
          message: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
  ),
};

FileRejectionItems.defaultProps = {
  fileRejections: [],
};

export default FileRejectionItems;
