import { QuestionMarkRounded } from '@mui/icons-material';
import { Box, Divider, Grid, IconButton, Paper, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import LogoDark from '@/assets/images/brand/corellia_dark.png';
import Logo from '@/assets/images/brand/corellia_light.png';
import { UserContext } from '@/contexts';
import ThemeStore from '@/theme/ThemeStore';

import Menu from './components/Menu';
import Notification from './components/Notification';
import { UserDropdown } from './components/UserDropdown';

const Header = () => {
  const { t } = useTranslation();
  const themeSelected = ThemeStore((state) => state.theme);

  return (
    <UserContext.Consumer>
      {({ user }) => (
        <Paper
          elevation={0}
          square
          sx={{
            height: 64,
            px: 2,
            border: 0,
            borderBottom: 1,
            borderStyle: 'solid',
            borderColor: 'divider',
            display: 'flex',
            alignItems: 'center',
            gap: 3,
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <Box
            sx={{
              width: 214,
              display: { xs: 'none', sm: 'none', md: 'flex' },
              alignItems: 'center',
              ml: 5,
              mr: 5,
            }}
          >
            <img
              src={themeSelected === 'light' ? Logo : LogoDark}
              alt="Corellia"
              width="100%"
            />
          </Box>
          <Divider
            orientation="vertical"
            sx={{
              height: '60%',
              display: { xs: 'none', sm: 'none', md: 'flex' },
            }}
          />
          <Grid container sx={{ height: '100%', alignItems: 'center' }}>
            <Grid
              item
              xs={6}
              sm={6}
              sx={{
                display: 'flex',
                justifyContent: 'start',
                gap: 5,
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Menu user={user} />
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <IconButton component={Link} to="/faq" id="link-faq">
                <Tooltip title={t('common.FAQ')}>
                  <QuestionMarkRounded />
                </Tooltip>
              </IconButton>
              <Notification />
              <UserDropdown />
            </Grid>
          </Grid>
        </Paper>
      )}
    </UserContext.Consumer>
  );
};

export default Header;
