import { useQuery } from 'react-query';

import axios from '@/utils/axios';

export const getProtocols = async () => {
  const { data } = await axios.get(`/protocols`);
  return data;
};

export const getIndexByNameAndProtocol = async (name, protocol) => {
  const { data } = await axios.get(
    `/indexsequences/search/?protocol_id=${protocol}&name=${name}`,
  );
  return data ? data[0] : null;
};

export const getIndexByProtocol = async (protocol) => {
  const { data } = await axios.get(
    `/indexsequences/search/?protocol_id=${protocol}`,
  );
  return data;
};

export const useIndexesByProtocol = (protocol) =>
  useQuery(['indexes', protocol], () => getIndexByProtocol(protocol));

export const useProtocols = () => useQuery(['protocols'], () => getProtocols());
