import 'nprogress/nprogress.css';
import 'react-toastify/dist/ReactToastify.css';

import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import enLocale from 'dayjs/locale/en-gb';
import frLocale from 'dayjs/locale/fr';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastContainer } from 'react-toastify';

import { getMe } from '@/api';
import { UserContext } from '@/contexts';
import Router from '@/router/AppRouter';
import generateTheme from '@/theme';
import ThemeStore from '@/theme/ThemeStore';
import {
  deleteRefreshToken,
  refreshToken,
  setAccessToken,
} from '@/utils/access_token';
import queryClient from '@/utils/react-query';

import AppLoader from './components/AppLoader';
import AppMaintenance from './components/AppMaintenance/AppMaintenance';
import history from './router/browserHistory';

const App = () => {
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const themeSelected = ThemeStore((state) => state.theme);
  const theme = useMemo(
    () => generateTheme(themeSelected, i18n.language),
    [themeSelected, i18n.language],
  );

  const redirectToLogin = () => {
    const from = history.location.pathname;
    deleteRefreshToken();

    if (!['/login', '/forgotten-password', '/reset-password'].includes(from)) {
      const redirectTo = !['/', '/login'].includes(from)
        ? `?redirect-to=${from}`
        : '';

      history.replace(`/login${redirectTo}`);
    }
  };

  const localeMap = {
    en: enLocale,
    fr: frLocale,
  };

  useEffect(() => {
    // Show AppLoader for at least 1s to prevent the component flashing
    Promise.allSettled([
      refreshToken(),
      new Promise((resolve) => setTimeout(() => resolve('resolved'), 2500)),
    ]).then(([{ status, value }]) => {
      if (status === 'fulfilled') {
        setAccessToken(value.data.access_token);
        getMe()
          .then((res) => {
            setUser(res);
            setLoading(false);
          })
          .catch(() => {
            redirectToLogin();
          });
      } else {
        redirectToLogin();
        setLoading(false);
      }
    });
  }, []);

  if (process.env.REACT_APP_ON_MAINTENANCE === 'true') {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AppMaintenance />
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {loading ? (
          <AppLoader />
        ) : (
          <UserContext.Provider value={{ user, setUser }}>
            <QueryClientProvider client={queryClient}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={localeMap[i18n.language]}
              >
                <Router />
                <ReactQueryDevtools
                  initialIsOpen={false}
                  position="bottom-right"
                />
              </LocalizationProvider>
            </QueryClientProvider>
            <ToastContainer
              theme={themeSelected === 'dark' ? 'dark' : 'light'}
              limit={3}
              style={{ width: 400 }}
            />
          </UserContext.Provider>
        )}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
