import axios from 'axios';

// Commentaires à retirer quand le refresh token sera implémenté
let accessToken = '';

const setAccessToken = (token) => {
  accessToken = token;
  // localStorage.setItem('accessToken', token);
};

// const getAccessToken = () => localStorage.getItem('accessToken');
const getAccessToken = () => accessToken;

const setRefreshToken = (token) => {
  localStorage.setItem('refreshToken', token);
};

const getRefreshToken = () => localStorage.getItem('refreshToken');

const deleteRefreshToken = () => {
  localStorage.removeItem('refreshToken');
};

const refreshToken = () => {
  const params = new URLSearchParams();

  const data = {
    grant_type: 'refresh_token',
  };
  Object.entries(data).forEach(([key, value]) => {
    params.append(key, value);
  });
  return axios.post(`${process.env.REACT_APP_API_URL}/token`, params, {
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${getRefreshToken()}`,
    },
  });
};

export {
  deleteRefreshToken,
  getAccessToken,
  getRefreshToken,
  refreshToken,
  setAccessToken,
  setRefreshToken,
};
