import { useMutation, useQuery, useQueryClient } from 'react-query';

import axios from '@/utils/axios';

export const getAllSamples = async () => {
  const { data } = await axios.get('/samples/');
  return data;
};

export const getSampleNameExist = async (name, demandId) => {
  const res = await axios.get(`/samples/${name}/exists?demand_id=${demandId}`);

  return res.data.details === 'Exists';
};

export const getPlateNameExist = async (name, demandId, batchId) => {
  let batch = '';
  if (batchId != null) {
    batch = `&batch_id=${batchId}`;
  }
  const res = await axios.get(
    `demands/plate_exists/?plate_name=${name}&demand_id=${demandId}${batch}`,
  );

  return res.data.details === 'Exists';
};

const formatSampleData = (sample, batch, formatId) => {
  const sampleData = {
    obj_in: {
      name: sample.name,
      sample_batch_id: batch,
    },
    format: formatId,
    values: [],
  };
  const regex = /^(spv_)/;
  Object.keys(sample).forEach((key) => {
    if (regex.test(key)) {
      sampleData.values.push({
        value: sample[key],
        label: key.slice(4),
      });
    }
  });
  return sampleData;
};

const formatSampleDataForUpdate = (values) => {
  const sampleData = {
    obj_in: {
      name: values.name,
    },
    values: [],
  };
  const regex = /^(spv_)/;
  Object.keys(values).forEach((key) => {
    if (regex.test(key)) {
      sampleData.values.push({
        value: values[key],
        label: key.slice(4),
      });
    }
  });
  return sampleData;
};

const formatSampleFromBatch = async (samples) => {
  const datas = await Promise.all(
    samples.items.map(async (sample) => {
      const samplesProperties = await axios
        .get(`samples/properties/values/?sample_id=${sample.id}`)
        .then(async ({ data }) => {
          const d = {};
          data.forEach((item) => {
            d[item.sample_property.label] = {
              value: item.value,
              id: item.sample_property.id,
              type: null,
              extra: item.extra,
            };
          });
          return d;
        });
      return {
        id: sample.id,
        name: sample.name,
        batchId: sample.sample_batch.id,
        batchPosition: sample.sample_batch.status.sort,
        sample_batch: sample.sample_batch.id,
        ...samplesProperties,
      };
    }),
  );
  return datas;
};

// fonction de création d'un tableau ayant une liste des plaques présente dans les samples
const formatPlate = (samples) => {
  const plates = {};
  if (samples) {
    samples.forEach((sample) => {
      // check si la cle "Identifiant de la plaque" existe dans le sample
      if ('plate_ext_id' in sample) {
        // check si la plaque existe déjà dans le tableau
        const key = sample.plate_ext_id.value;
        if (!(key in plates)) {
          plates[key] = [];
        }
        plates[key].push(sample.well.value);
      }
    });
  }
  return plates;
};

export const getAllSamplesInBatch = async (batch) => {
  if (batch == null) {
    return { items: [], plates: {} };
  }

  const size = 10;
  const { data } = await axios.get(
    `/samples/?sample_batch_id=${batch}&size=${size}`,
  );
  if (data.pages > 0) {
    // eslint-disable-next-line prefer-const
    const donnees = await Promise.all(
      Array(data.pages)
        .fill()
        .map(async (_, i) => {
          const reponse = await axios
            .get(
              `/samples/?sample_batch_id=${batch}&size=${size}&page=${i + 1}`,
            )
            .then((datas) => datas.data.items);
          return reponse;
        }),
    );
    const samplesObject = { items: donnees.flat() };
    const sampleFormatted = await formatSampleFromBatch(samplesObject);
    const plates = formatPlate(sampleFormatted);
    if (sampleFormatted.length !== 0) {
      return {
        items: sampleFormatted,
        plates,
        batch: sampleFormatted[0].batchPosition,
      };
    }
  }

  return { items: [], plates: {} };
};

export const setBatchStatus = async ({
  batchId,
  batchStatus,
  demandId,
  demandStatus,
}) => {
  await axios.put(`/samples/batches/${batchId}`, {
    status_id: batchStatus,
  });

  if (batchStatus === 2 && demandStatus === 2) {
    await axios.put(`/demands/${demandId}`, {
      status_id: 3,
    });
  }
};

export const createSample = async ({
  sample,
  demandId,
  formatId,
  batchId,
  formatIndex,
}) => {
  if (batchId === null) {
    const { data: batch } = await axios.post('/samples/batches/', {
      demand_id: demandId,
      status: 1,
      format_id: formatId,
      reverse_complement: +formatIndex === 1,
      created_at: new Date(),
    });

    const sampleData = formatSampleData(sample, batch.id, formatId);
    const { data: samples } = await axios.post(
      '/samples/with_propvals',
      sampleData,
    );

    return samples;
  }

  const sampleData = formatSampleData(sample, batchId);
  const { data } = await axios.post('/samples/with_propvals', sampleData);
  return data;
};

export const updateStatusSample = async ({ sampleId, values }) => {
  const { data } = await axios.put(`/samples/bioit/${sampleId}`, values);
  return data;
};

export const updateMultipleStatusSample = async (obj) => {
  const { data } = await axios.put(`/samples/bioit/`, obj);
  return data;
};

export const updateSample = async ({ sampleId, values, batchId }) => {
  const sample = formatSampleDataForUpdate(values);
  const { data } = await axios.put(
    `/samples/with_propvals/${sampleId}`,
    sample,
  );

  await axios.get(`/samples/batches/${batchId}`).then(async (batch) => {
    if (batch.status.sort === 2) {
      await axios.put(`/samples/batches/${batchId}`, {
        status_id: 3,
      });
    }
  });

  return data;
};

export const deleteSample = async ({ id }) => {
  const { data } = await axios.delete(`/samples/${id}/with_propvals`);
  return data;
};

export const deleteAllSamples = async (samplesId) => {
  const { data } = await axios.delete(`/samples/with_propvals/`, {
    data: samplesId,
  });
  return data;
};

export const useSamplesByBatch = (batch) =>
  useQuery(['samplesDemand', batch], () => getAllSamplesInBatch(batch), {
    refetchOnWindowFocus: false,
  });

export const useCreateSample = () => {
  const queryClient = useQueryClient();

  return useMutation((newSample) => createSample(newSample), {
    onMutate: async () => {
      await queryClient.cancelQueries(['samplesDemand']);
      const prevSamples = queryClient.getQueryData(['samplesDemand']);
      // queryClient.setQueryData(['allUsers'], [...prevUsers, {...newUser, enabled: true}]);
      return { prevSamples };
    },
    onError: (_, __, { prevSamples }) => {
      queryClient.setQueryData(prevSamples);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['samplesDemand']);
      queryClient.invalidateQueries(['demands']);
    },
  });
};

export const useUpdateSample = () => {
  const queryClient = useQueryClient();

  return useMutation((newSample) => updateSample(newSample), {
    onMutate: async () => {
      await queryClient.cancelQueries(['samplesDemand']);
      const prevSamples = queryClient.getQueryData(['samplesDemand']);
      return { prevSamples };
    },
    onError: (_, __, { prevSamples }) => {
      queryClient.setQueryData(prevSamples);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['samplesDemand']);
      queryClient.invalidateQueries(['demands']);
    },
  });
};

export const useUpdateStatusSample = () => {
  const queryClient = useQueryClient();

  return useMutation(updateStatusSample, {
    onSettled: () => {
      queryClient.invalidateQueries(['fastqc']);
      queryClient.invalidateQueries(['fastqc_pool']);
      queryClient.invalidateQueries(['fastqc_export_complements']);
    },
  });
};

export const useUpdateMultipleStatusSample = () => {
  const queryClient = useQueryClient();

  return useMutation(updateMultipleStatusSample, {
    onSettled: () => {
      queryClient.invalidateQueries(['fastqc']);
      queryClient.invalidateQueries(['fastqc_pool']);
      queryClient.invalidateQueries(['fastqc_export_complements']);
    },
  });
};

export const useUpdateBatchStatus = () => {
  const queryClient = useQueryClient();

  return useMutation((datas) => setBatchStatus(datas), {
    onMutate: async ({ batchId, batchStatus }) => {
      await queryClient.cancelQueries(['samplesDemand', batchId]);
      const prevBatch = queryClient.getQueryData(['samplesDemand', batchId]);
      queryClient.setQueryData(['samplesDemand', batchId], {
        ...prevBatch,
        batch: batchStatus,
      });
      return { prevBatch };
    },
    onError: (_, newBatch, { prevBatch }) => {
      queryClient.setQueryData(['samplesDemand', newBatch.batchId], prevBatch);
    },
    onSettled: (_, __, newBatch) => {
      queryClient.invalidateQueries(['samplesDemand', newBatch.batchId]);
      queryClient.invalidateQueries(['demands']);
    },
  });
};

export const useDeleteSample = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteSample, {
    onMutate: async () => {
      await queryClient.cancelQueries(['samplesDemand']);
      const prevSamples = queryClient.getQueryData(['samplesDemand']);
      return { prevSamples };
    },
    onError: (_, __, { prevSamples }) => {
      queryClient.setQueryData(prevSamples);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['samplesDemand']);
      queryClient.invalidateQueries(['demands']);
    },
  });
};

export const useDeleteAllSamples = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteAllSamples, {
    onMutate: async () => {
      await queryClient.cancelQueries(['samplesDemand']);
      const prevSamples = queryClient.getQueryData(['samplesDemand']);
      return { prevSamples };
    },
    onError: (_, __, { prevSamples }) => {
      queryClient.setQueryData(prevSamples);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['samplesDemand']);
      queryClient.invalidateQueries(['demands']);
    },
  });
};

/*
  Format the sample data to be sent to the server. Values are integer in sample, key = sample_property_id, value = value, sample_id = sample_id 
  {
    "sample": {
      "name": "string",
      "volume": 0,
      "concentration": 0,
      "quantity": 0,
      "results_at": "2022-08-23T13:03:23.350Z",
      "format_id": 0,
      "sample_type_id": 0,
      "sample_batch_id": 0
    },
    "values": [
      {
        "value": "string",
        "sample_id": 0,
        "sample_property_id": 0
      }
    ]
  }
 */
