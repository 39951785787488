import './Loader.css';

import { Box } from '@mui/material';
import PropTypes from 'prop-types';

const Loader = ({ fromApp }) => (
  <Box
    display="flex"
    flexDirection="column"
    flexGrow={!fromApp && 1}
    justifyContent="center"
    alignItems="center"
    minHeight={!fromApp && '50vh'}
  >
    <div className="dna-container">
      <div className="dna-bra dna-bra-1" />
      <div className="dna-bra dna-bra-2" />
      <div className="dna-bra dna-bra-3" />
      <div className="dna-bra dna-bra-4" />
      <div className="dna-bra dna-bra-5" />
      <div className="dna-bra dna-bra-6" />
      <div className="dna-bra dna-bra-7" />
      <div className="dna-bra dna-bra-8" />
    </div>
  </Box>
);

Loader.propTypes = {
  fromApp: PropTypes.bool,
};

Loader.defaultProps = {
  fromApp: false,
};

export default Loader;
