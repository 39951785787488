import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const AcceptedFilesItems = ({ acceptedFiles, onDelete }) => (
  <List dense sx={{ maxHeight: '190px', overflow: 'auto' }}>
    {acceptedFiles.map((file) => (
      <ListItem
        key={file.name}
        divider
        secondaryAction={
          file.status === 'uploading' ? (
            <CircularProgress size="1em" />
          ) : (
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(file);
              }}
              sx={{
                border: 1,
                borderStyle: 'solid',
                borderColor: 'secondary.main',
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          )
        }
      >
        <ListItemIcon>
          {file.status === 'uploaded' ? (
            <CheckCircleOutlineRoundedIcon color="success" fontSize="small" />
          ) : (
            <UploadFileIcon color="default" fontSize="small" />
          )}
        </ListItemIcon>
        <Tooltip title={file.name.length > 40 ? file.name : ''}>
          <ListItemText
            primary={file.name}
            primaryTypographyProps={{
              noWrap: true,
              style: { width: '40ch' },
            }}
          />
        </Tooltip>
      </ListItem>
    ))}
  </List>
);

AcceptedFilesItems.propTypes = {
  acceptedFiles: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      path: PropTypes.string,
    }),
  ),
  onDelete: PropTypes.func.isRequired,
};

AcceptedFilesItems.defaultProps = {
  acceptedFiles: [],
};

export default AcceptedFilesItems;
