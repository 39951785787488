import axios from 'axios';

import history from '@/router/browserHistory';

import { getAccessToken, refreshToken, setAccessToken } from './access_token';

const instanceAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

instanceAxios.interceptors.request.use((config) => {
  const newConfig = { ...config };
  const accessToken = getAccessToken();
  const lng = localStorage.getItem('i18nextLng');

  newConfig.headers.Authorization = `Bearer ${accessToken}`;
  newConfig.headers['Accept-Language'] = lng || 'en';

  return newConfig;
});

instanceAxios.interceptors.response.use(
  (res) => res,
  (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401) {
      return refreshToken()
        .then((res) => {
          setAccessToken(res.data.access_token);

          return instanceAxios(originalRequest);
        })
        .catch(() => {
          const from = history.location.pathname;

          const redirectTo =
            from !== '/' && from !== '/login' ? `?redirect-to=${from}` : '';

          history.replace(`/login${redirectTo}`);
          window.location = `${window.location.protocol}//${window.location.host}/login${redirectTo}`;
        });
    }

    return Promise.reject(error);
  },
);

export default instanceAxios;
