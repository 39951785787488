import { CloudUpload } from '@mui/icons-material';
import { Grid, MenuItem, Stack, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { Select, TextField } from 'formik-mui';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { createJiraCard } from '@/api';
import FileDropper from '@/components/FileDropper';

const JiraForm = ({ setJiraDeclaration, setIsLoading }) => {
  const { t } = useTranslation();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const formValues = {
    summary: '',
    description: '',
    issuetype: '',
    file: '',
  };

  return (
    <Formik
      initialValues={formValues}
      enableReinitialize
      validationSchema={Yup.object().shape({
        summary: Yup.string().required(t('jira.form-error.summary_required')),
        description: Yup.string().required(
          t('jira.form-error.description_required'),
        ),
        issuetype: Yup.string().required(
          t('jira.form-error.issuetype_required'),
        ),
      })}
      onSubmit={(values) => {
        setIsLoading(true);
        createJiraCard(values, uploadedFiles)
          .then(() => {
            toast.success(t('jira.success'), {
              position: 'bottom-right',
            });
            setJiraDeclaration(false);
            setIsLoading(false);
          })
          .catch(() => {
            toast.error(t('jira.error'), {
              position: 'bottom-right',
            });
            setIsLoading(false);
            setJiraDeclaration(false);
          });
      }}
    >
      <Form id="jira-form">
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Field
              component={TextField}
              name="summary"
              label={t('jira.summary')}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              component={Select}
              name="issuetype"
              label={t('jira.issuetype')}
              formControl={{ sx: { width: '100%' } }}
              required
            >
              <MenuItem value="Submit a request or incident">
                {t('jira.bug')}
              </MenuItem>
              <MenuItem value="Tâche">{t('jira.task')}</MenuItem>
            </Field>
          </Grid>
          <Grid item xs={12}>
            <Field
              component={TextField}
              name="description"
              label={t('jira.description')}
              fullWidth
              multiline
              rows={4}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FileDropper
              multiFile
              maxFiles={5}
              allowedExtensions={{
                'image/*': ['.png', '.jpg', '.gif', '.jpeg'],
                'text/*': ['.txt', '.pdf', '.csv'],
                'application/*': ['.pdf', '.xlsx'],
              }}
              containerSx={{
                overflow: 'auto',
                backgroundColor: 'background.paper',
              }}
              content={({
                isDragActive,
                maxFiles,
                files,
                allowedExtensions,
              }) => (
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    flex: '1',
                    maxWidth: 'fit-content',
                    margin: '0 3rem',
                  }}
                >
                  <CloudUpload
                    className="cloud-upload-icon"
                    sx={{
                      fontSize: isDragActive ? '54px' : '50px',
                      color: isDragActive ? 'primary.main' : 'primary.light',
                      transition: 'font-size 0.25s ease, color 0.25s ease',
                    }}
                  />
                  {isDragActive ? (
                    <Typography element="p">
                      {t('filezone.drag_active')}
                    </Typography>
                  ) : (
                    <>
                      <Typography element="p">
                        {t('filezone.dropzone_text')}
                      </Typography>
                      <Typography variant="caption">
                        {t('filezone.files_remaining', {
                          count: maxFiles - files.length,
                        })}
                      </Typography>
                      {allowedExtensions && files.length === 0 && (
                        <Typography variant="caption">
                          {t('filezone.allowed_extensions')} :
                          {Object.values(allowedExtensions)
                            .flat()
                            .reduce((acc, curr) => [acc, ', ', curr])}
                        </Typography>
                      )}
                    </>
                  )}
                </Stack>
              )}
              onChange={(files) => {
                setUploadedFiles(files);
              }}
            />
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
};

JiraForm.propTypes = {
  setJiraDeclaration: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

export default JiraForm;
