import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-chained-backend';
import HttpApi from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    supportedLngs: ['fr', 'en'],
    fallbackLng: 'fr',
    defaultNS: 'translation',
    backend: {
      backends: [
        LocalStorageBackend, // primary backend
        HttpApi, // fallback backend
      ],
      backendOptions: [
        {
          expirationTime: 1000,
          /* options for primary backend */
        },
        {
          /* options for secondary backend */
          loadPath: '/locales/{{lng}}/{{ns}}.json', // http load path for my own fallback
        },
      ],
    },
    debug: false,
    interpolation: { escapeValue: false },
    initImmediate: false,
  });

export default i18n;
