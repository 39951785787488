import {
  CircleRounded,
  GraphicEqRounded,
  HomeRounded,
  KeyboardArrowRightRounded,
} from '@mui/icons-material';
import { Box, Link as MuiLink, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

const Menu = ({ user }) => {
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 3,
        height: '100%',
      }}
    >
      <MuiLink
        component={Link}
        to="/"
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
          py: '0.5rem',
          borderBottom: location.pathname === '/' ? 2 : 0,
          borderBottomColor: 'primary.main',
          height: '100%',
        }}
        color="inherit"
        underline="none"
      >
        <HomeRounded />{' '}
        <Typography
          variant="body2"
          sx={{ display: { xs: 'none', sm: 'block' } }}
        >
          {t('home')}
        </Typography>
      </MuiLink>
      {user.role === 'user' && (
        <>
          <CircleRounded sx={{ fontSize: '0.4rem', color: 'divider' }} />
          <MuiLink
            component={Link}
            to="/projects"
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              fontSize: '0.875rem',
              py: '0.5rem',
              borderBottom: location.pathname === '/projects' ? 2 : 0,
              borderBottomColor: 'primary.main',
              height: '100%',
            }}
            color="inherit"
            underline="none"
          >
            <KeyboardArrowRightRounded /> {t('my_projects')}
          </MuiLink>
        </>
      )}
      {(user.role === 'super_admin' ||
        user.role === 'admin' ||
        user.role === 'bioinf') && (
        <MuiLink
          component={Link}
          to="/bioit/dashboard"
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            py: '0.5rem',
            borderBottom: location.pathname === '/bioit/dashboard' ? 2 : 0,
            borderBottomColor: 'primary.main',
            height: '100%',
          }}
          color="inherit"
          underline="none"
        >
          <GraphicEqRounded />{' '}
          <Typography
            variant="body2"
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
            {t('complements_gestion')}
          </Typography>
        </MuiLink>
      )}
    </Box>
  );
};

Menu.propTypes = {
  user: PropTypes.shape().isRequired,
};

export default Menu;
