import { pickBy } from 'lodash';
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';

import axios from '@/utils/axios';

export const getAllDemands = async (
  search,
  orderby,
  status,
  page,
  pageSize,
) => {
  let url = '/demands/';
  const statusIds = status && status.map((s) => `status_ids=${s}`).join('&');
  if (statusIds) {
    url += `?${statusIds}`;
  }
  const searchParams = new URLSearchParams(
    pickBy(
      {
        include: 'contacts,batches',
        name: search,
        sort:
          orderby && orderby.length > 0
            ? (orderby[0].desc ? '-' : '+') + orderby[0].id
            : undefined,
        page: page + 1,
        size: pageSize,
      },
      (value) => Boolean(value),
    ),
  );

  if (searchParams) {
    if (statusIds) {
      url += `&${searchParams.toString()}`;
    } else {
      url += `?${searchParams.toString()}`;
    }
  }
  const { data } = await axios.get(url);
  // Json parse seq_sizes_qte
  data.items = data.items.map((demand) => ({
    ...demand,
    seq_sizes_qte: JSON.parse(demand.seq_sizes_qte),
  }));
  return data;
};

export const deleteDemand = async (id) => {
  const { data } = await axios.delete(`/demands/${id}?cascade=true`);
  return data;
};

export const getDemands = async () => {
  const { data } = await axios.get('/demands/');
  data.items = data.items.map((demand) => ({
    ...demand,
    seq_sizes_qte: JSON.parse(demand.seq_sizes_qte),
  }));
  return data;
};

export const getDemand = async (id) => {
  const { data } = await axios.get(`/demands/${id}?include=batches,contacts`);
  data.seq_sizes_qte = JSON.parse(data.seq_sizes_qte);
  return data;
};

export const putDemand = async ({ values, demandId }) => {
  const sendingValues = {
    ...values,
    seq_sizes_qte: JSON.stringify(values.seq_sizes_qte),
  };
  const { data } = await axios.put(`/demands/${demandId}`, sendingValues);
  return data;
};

export const putCustomNameDemand = async ({ demandId, name }) => {
  const sendingValues = {
    client_name: name,
  };
  const { data } = await axios.put(`/demands/${demandId}`, sendingValues);
  return data;
};

export const getBatchesByDemand = async (id) => {
  const { data } = await axios.get(`samples/batches/?demand_id=${id}`);
  return data;
};

export const getAllBatchesStatus = async () => {
  const { data } = await axios.get('/samples/batches/status');
  return data;
};

export const getAllReturns = async (search, orderby, status) => {
  let url = '/demands/returns/?size=100';
  if (status) {
    url += `&status=${status}`;
  }
  const { data } = await axios.get(url);
  return data;
};

export const getReturn = async (id) => {
  const url = `/demands/returns/${id}`;
  const { data } = await axios.get(url);
  return data;
};

export const getReturnDemandAllowed = async (id) => {
  const url = `/demands/return_update_allowed/${id}`;
  const { data } = await axios.get(url);
  return data;
};

export const updateDemandReturn = async (demandReturn) => {
  const { data } = await axios.put(
    `/demands/returns/${demandReturn.id}`,
    demandReturn,
  );
  return data;
};

export const deleteDemandReturnByDemandId = async (demandId) => {
  const { data } = await axios.get(`/demands/returns/${demandId}`);
  if (typeof data === 'undefined') {
    return;
  }
  await axios.delete(`/demands/returns/${data.id}`);
};

export const replaceDemandReturn = async (demandReturn) => {
  deleteDemandReturnByDemandId(demandReturn.demand_id);
  const { data } = await axios.post(`/demands/returns/`, demandReturn);
  return data;
};

export const updateDemandNeedReturn = async (demand) => {
  const { data } = await axios.put(`/demands/${demand.id}`, {
    need_return: demand.need_return,
  });
  return data;
};

export const getShipmentsByReturnId = async (demandReturnId) => {
  const { data } = await axios.get(
    `/demands/returns/shipments?demand_return_id=${demandReturnId}`,
  );
  return data;
};

export const createReturnShipment = async (shipment) => {
  const { data } = await axios.post('/demands/returns/shipments', shipment);
  return data;
};

export const updateDemandStatus = async (id, status, callback) => {
  const { data } = await axios
    .put(`/demands/${id}`, { status_id: status })
    .then(callback);
  return data;
};

export const upDemandStatus = async (demand) => {
  const { data } = await axios.put(`/demands/${demand.id}`, {
    status_id: demand.status,
  });
  return data;
};

export const updateInitializeDemand = async (id, objIn, callback) => {
  const { data } = await axios.put(`/demands/${id}`, objIn).then(callback);
  return data;
};

export const getFormatByDemandType = async (demandTypeId) => {
  const { data } = await axios.get(
    `/samples/formats?enabled=true&demand_type_id=${demandTypeId}`,
  );
  return data;
};

export const customerRecovery = async (demandId) => {
  const { data } = await axios.post(`/demands/resend_demand_mail/${demandId}`);
  return data;
};

export const getMatMets = async () => {
  const { data } = await axios.get('/demands/matmet');
  return data;
};

export const useDemands = (filters) =>
  useQuery(
    ['demands', filters],
    () =>
      getAllDemands(
        filters.search,
        filters.orderby,
        filters.status,
        filters.page,
        filters.size,
      ),
    {
      placeholderData: keepPreviousData,
    },
  );

export const useUpdateDemandStatus = () => {
  const queryClient = useQueryClient();

  return useMutation(upDemandStatus, {
    onSettled: () => {
      queryClient.invalidateQueries(['demands']);
    },
  });
};

export const useDemandsWithoutFilter = () =>
  useQuery(['demands'], () => getDemands());

export const useDemand = (id, options) =>
  useQuery(['demands', id], () => getDemand(id), options);

export const useFormatByDemandType = (demandTypeId) =>
  useQuery(['format', demandTypeId], () => getFormatByDemandType(demandTypeId));

export const useBatchesByDemand = (id) =>
  useQuery(['batches', id], () => getBatchesByDemand(id));

export const useBatches = () =>
  useQuery(['batchesStatus'], getAllBatchesStatus);

export const useReturns = (filters) =>
  useQuery(['returns', filters], () =>
    getAllReturns(filters.search, filters.orderby, filters.status),
  );

export const useReturn = (id) => useQuery(['returns', id], () => getReturn(id));

export const useReturnDemandAllowed = (id) =>
  useQuery(['return_demand_allowed', id], () => getReturnDemandAllowed(id));

export const useShipments = (demandReturnId) =>
  useQuery(['shipments', demandReturnId], () =>
    getShipmentsByReturnId(demandReturnId),
  );

export const useCreateShipment = () => {
  const queryClient = useQueryClient();

  return useMutation(createReturnShipment, {
    onSettled: () => {
      queryClient.invalidateQueries(['returns']);
      queryClient.invalidateQueries(['shipments']);
    },
  });
};

export const useUpdateDemandReturn = () => {
  const queryClient = useQueryClient();

  return useMutation(updateDemandReturn, {
    onSettled: () => {
      queryClient.invalidateQueries(['returns']);
    },
  });
};

export const useUpdateDemandNeedReturn = () => {
  const queryClient = useQueryClient();

  return useMutation(updateDemandNeedReturn, {
    onSettled: () => {
      queryClient.invalidateQueries(['demands']);
    },
  });
};

export const useEditDemand = () => {
  const queryClient = useQueryClient();
  return useMutation(putDemand, {
    onSettled: () => {
      queryClient.invalidateQueries(['demands']);
    },
  });
};

export const useEditMatMetDemand = () => {
  const queryClient = useQueryClient();

  return useMutation(putDemand, {
    onMutate: async ({ demandId, matmet }) => {
      const id = demandId.toString();
      await queryClient.cancelQueries(['demands', id]);
      const prevDemand = queryClient.getQueryData(['demands', id]);
      queryClient.setQueryData(['demands', id], {
        ...prevDemand,
        matmet,
      });
      return { prevDemand };
    },
    onError: ({ prevDemand }) => {
      queryClient.setQueryData(prevDemand);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['demands']);
    },
  });
};

export const useMatmets = () => useQuery(['matmets'], () => getMatMets());
