import {
  BugReportRounded,
  LogoutRounded,
  Password as PasswordIcon,
  PeopleAltRounded,
  PersonRounded,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Typography,
} from '@mui/material';
import { Suspense, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { needLogout } from '@/api';
import JiraDialog from '@/components/JiraDialog';
import { UserContext } from '@/contexts';
import { deleteRefreshToken } from '@/utils/access_token';

import LanguageDropdown from '../LanguageDropdown';
import ThemeSwitcher from '../ThemeSwitcher';

const UserDropdown = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [jiraDeclaration, setJiraDeclaration] = useState(false);
  const open = Boolean(anchorEl);
  const buttonMenuRef = useRef(null);
  const handleClick = () => {
    setAnchorEl(buttonMenuRef.current);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    needLogout()
      .then(() => {
        deleteRefreshToken();
        toast.success(t('auth.logout_success'), {
          position: 'bottom-right',
        });
        navigate('/login', { replace: true });
      })
      .catch(() => {
        toast.error(t('auth.logout_error'), {
          position: 'bottom-rig ht',
        });
      });
  };

  const handleOpenJira = () => {
    setJiraDeclaration(true);
    handleClose();
  };

  return (
    <UserContext.Consumer>
      {({ user }) => (
        <div>
          <Box
            onClick={handleClick}
            ref={buttonMenuRef}
            sx={{
              display: 'flex',
              gap: 1,
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center',
                border: 1,
                borderColor: 'divider',
                px: 1,
                py: 0.7,
                borderRadius: 10,
              }}
            >
              <Typography variant="body2" sx={{ mr: 1 }}>
                {user.full_name}
              </Typography>
              <Avatar
                sx={{
                  backgroundColor: 'primary.main',
                  color: 'text.primary',
                  width: 32,
                  height: 32,
                }}
                id="basic-button-usermenu"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <PersonRounded />
              </Avatar>
            </Box>
          </Box>
          <Menu
            id="basic-menu-usermenu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': `basic-button-usermenu`,
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              mt: 1.5,
            }}
          >
            <List
              sx={{
                width: '300px',
                py: 0,
              }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              {user.role === 'super_admin' && (
                <>
                  <ListItemButton
                    component={Link}
                    to="/users"
                    onClick={handleClose}
                  >
                    <ListItemIcon>
                      <PeopleAltRounded />
                    </ListItemIcon>
                    <ListItemText primary={t('common.users_gestion')} />
                  </ListItemButton>
                  <Divider />
                </>
              )}
              <ListItemButton onClick={handleOpenJira}>
                <ListItemIcon>
                  <BugReportRounded />
                </ListItemIcon>
                <ListItemText primary={t('common.problem')} />
              </ListItemButton>
              <Divider />
              <ThemeSwitcher />
              <Suspense fallback={<li>Loading...</li>}>
                <LanguageDropdown fromNav />
              </Suspense>
              <Divider />
              <ListItemButton
                component={Link}
                to="/change-password"
                onClick={handleClose}
              >
                <ListItemIcon>
                  <PasswordIcon />
                </ListItemIcon>
                <ListItemText primary={t('auth.modify_password')} />
              </ListItemButton>
              <ListItemButton onClick={() => handleLogout()}>
                <ListItemIcon>
                  <LogoutRounded />
                </ListItemIcon>
                <ListItemText primary={t('common.logout')} />
              </ListItemButton>
            </List>
          </Menu>
          <JiraDialog
            setJiraDeclaration={setJiraDeclaration}
            open={jiraDeclaration}
          />
        </div>
      )}
    </UserContext.Consumer>
  );
};

export default UserDropdown;
