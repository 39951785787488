import 'dayjs/locale/fr';
import 'dayjs/locale/en';

import { FiberManualRecordRounded } from '@mui/icons-material';
import { Box, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  useSeenDemandNotification,
  useSeenNotification,
  useUnSeenDemandNotification,
  useUnSeenNotification,
} from '@/api';
import ShipmentReceivedIcon from '@/assets/images/icons/logistic/shipment_received.png';
import ShipmentReturnIcon from '@/assets/images/icons/logistic/shipment_return.png';
import TrashIcon from '@/assets/images/icons/logistic/trash.png';

const BlockBatch = ({ notification, active, setActive }) => {
  const { t, i18n } = useTranslation();
  const { mutate: seenNotification } = useSeenNotification();
  const { mutate: unseenNotification } = useUnSeenNotification();
  return (
    <Box
      key={`notification-batch-${notification.content.sample_batch_id}-${notification.content.user_id}`}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        p: 2,
        backgroundColor:
          active ===
          `notification-batch-${notification.content.sample_batch_id}-${notification.content.user_id}`
            ? 'background.default'
            : 'transparent',
      }}
      onMouseEnter={() =>
        setActive(
          `notification-batch-${notification.content.sample_batch_id}-${notification.content.user_id}`,
        )
      }
      onMouseLeave={() => setActive(null)}
    >
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <img src={ShipmentReceivedIcon} alt="Shipment received" width="40" />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 350,
          }}
        >
          <Typography variant="body2">
            {t('notification.shipment_received', {
              number: notification.content.sample_batch.number,
              pj: notification.content.sample_batch.demand.client_name
                ? notification.content.sample_batch.demand.client_name
                : notification.content.sample_batch.demand.name,
            })}
          </Typography>
          <Tooltip
            title={dayjs(notification.content.created_at)
              .locale(i18n.language)
              .format('L')}
            placement="bottom-start"
          >
            <Typography
              variant="caption"
              component="span"
              sx={{ color: 'text.disabled' }}
            >
              {dayjs(notification.content.created_at)
                .locale(i18n.language)
                .fromNow()}
            </Typography>
          </Tooltip>
        </Box>
        <Box
          sx={{ width: 30, height: 30 }}
          onClick={() =>
            notification.seen
              ? unseenNotification(notification)
              : seenNotification(notification)
          }
        >
          <Tooltip
            title={
              notification.seen
                ? t('notification.mark_as_unread')
                : t('notification.mark_as_read')
            }
          >
            <Box
              sx={{
                p: 0.4,
                border: 1,
                borderColor: 'transparent',
                borderRadius: 100,
                alignItems: 'center',
                justifyContent: 'center',
                display:
                  !notification.seen ||
                  (notification.seen &&
                    active ===
                      `notification-batch-${notification.content.sample_batch_id}-${notification.content.user_id}`)
                    ? 'flex'
                    : 'none',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  cursor: 'pointer',
                  borderColor: notification.seen
                    ? 'text.primary'
                    : 'primary.main',
                },
              }}
            >
              <FiberManualRecordRounded
                sx={{
                  fontSize: 16,
                  color: notification.seen ? 'text.primary' : 'primary.main',
                  transition: 'all 0.3s ease-in-out',
                }}
              />
            </Box>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

const BlockDemand = ({ notification, active, setActive }) => {
  const { t, i18n } = useTranslation();
  const { mutate: seenNotification } = useSeenDemandNotification();
  const { mutate: unseenNotification } = useUnSeenDemandNotification();

  return (
    <Box
      key={`notification-demand-${notification.content.id}-${notification.content.user_id}`}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        p: 2,
        backgroundColor:
          active ===
          `notification-demand-${notification.content.id}-${notification.content.user_id}`
            ? 'background.default'
            : 'transparent',
      }}
      onMouseEnter={() =>
        setActive(
          `notification-demand-${notification.content.id}-${notification.content.user_id}`,
        )
      }
      onMouseLeave={() => setActive(null)}
    >
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        {(notification.notification_type === 'returning' ||
          notification.notification_type === 'ask_return') && (
          <img src={ShipmentReturnIcon} alt="Demand return" width="40" />
        )}
        {(notification.notification_type === 'destruction' ||
          notification.notification_type === 'ask_destroy') && (
          <img src={TrashIcon} alt="Demand destroyed" width="40" />
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 350,
          }}
        >
          <Typography variant="body2">
            {notification.notification_type === 'returning' &&
              t('notification.demand_return', {
                pj: notification.content.demand.client_name
                  ? notification.content.demand.client_name
                  : notification.content.demand.name,
              })}
            {notification.notification_type === 'destruction' &&
              t('notification.demand_trash', {
                pj: notification.content.demand.client_name
                  ? notification.content.demand.client_name
                  : notification.content.demand.name,
              })}
            {notification.notification_type === 'ask_return' &&
              t('notification.ask_demand_return', {
                pj: notification.content.demand.name,
              })}
            {notification.notification_type === 'ask_destroy' &&
              t('notification.ask_demand_trash', {
                pj: notification.content.demand.name,
              })}
          </Typography>
          <Tooltip
            title={dayjs(notification.content.created_at)
              .locale(i18n.language)
              .format('L')}
            placement="bottom-start"
          >
            <Typography
              variant="caption"
              component="span"
              sx={{ color: 'text.disabled' }}
            >
              {dayjs(notification.content.created_at)
                .locale(i18n.language)
                .fromNow()}
            </Typography>
          </Tooltip>
        </Box>
        <Box
          sx={{ width: 30, height: 30 }}
          onClick={() =>
            notification.seen
              ? unseenNotification(notification)
              : seenNotification(notification)
          }
        >
          <Tooltip
            title={
              notification.seen
                ? t('notification.mark_as_unread')
                : t('notification.mark_as_read')
            }
          >
            <Box
              sx={{
                p: 0.4,
                border: 1,
                borderColor: 'transparent',
                borderRadius: 100,
                alignItems: 'center',
                justifyContent: 'center',
                display:
                  !notification.seen ||
                  (notification.seen &&
                    active ===
                      `notification-demand-${notification.content.id}-${notification.content.user_id}`)
                    ? 'flex'
                    : 'none',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  cursor: 'pointer',
                  borderColor: notification.seen
                    ? 'text.primary'
                    : 'primary.main',
                },
              }}
            >
              <FiberManualRecordRounded
                sx={{
                  fontSize: 16,
                  color: notification.seen ? 'text.primary' : 'primary.main',
                  transition: 'all 0.3s ease-in-out',
                }}
              />
            </Box>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

const NotificationBlock = ({ notification, active, setActive }) => {
  dayjs.extend(relativeTime);
  dayjs.extend(localizedFormat);

  return (
    <div>
      {notification.notification_type === 'samplebatch' ? (
        <BlockBatch
          notification={notification}
          active={active}
          setActive={setActive}
        />
      ) : (
        <BlockDemand
          notification={notification}
          active={active}
          setActive={setActive}
        />
      )}
    </div>
  );
};

export default NotificationBlock;

NotificationBlock.propTypes = {
  notification: PropTypes.shape().isRequired,
  active: PropTypes.number,
  setActive: PropTypes.func,
};

NotificationBlock.defaultProps = {
  active: null,
  setActive: () => {},
};

BlockBatch.propTypes = {
  notification: PropTypes.shape().isRequired,
  active: PropTypes.number.isRequired,
  setActive: PropTypes.func.isRequired,
};

BlockDemand.propTypes = {
  notification: PropTypes.shape().isRequired,
  active: PropTypes.number.isRequired,
  setActive: PropTypes.func.isRequired,
};
