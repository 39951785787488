import axios from '@/utils/axios';

export const createJiraCard = async (values, files) => {
  const formData = new FormData();
  formData.append('summary', values.summary);
  formData.append('description', values.description);
  formData.append('issuetype', values.issuetype);
  formData.append('project', 'EESD');
  files.map((file) => formData.append('files', file));

  const { data } = await axios.post('bug_report/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return data;
};
