import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import { UserContext } from '@/contexts';
import Layout from '@/layout/Layout';
import LoginLayout from '@/layout/LoginLayout';
import RequireAuth from '@/router/RequireAuth';

const Login = lazy(() => import('@/pages/Auth/Login'));
const ForgottenPassword = lazy(() => import('@/pages/Auth/ForgottenPassword'));
const RenewPassword = lazy(() => import('@/pages/Auth/RenewPassword'));
const AdminDashboard = lazy(() => import('@/pages/Dashboard/AdminDashboard'));
const IgDashboard = lazy(() => import('@/pages/Dashboard/IgDashboard'));
const LogisticDashboard = lazy(() =>
  import('@/pages/Dashboard/LogisticDashboard'),
);
const UserDashboard = lazy(() => import('@/pages/Dashboard/UserDashboard'));
const ProjectsList = lazy(() => import('@/pages/ProjectsList'));
const Demand = lazy(() => import('@/pages/Demand'));
const SamplesInformations = lazy(() =>
  import('@/pages/Demand/sub/SamplesInformations'),
);
const SamplesDetails = lazy(() => import('@/pages/Demand/sub/SamplesDetails'));
const ContactInformations = lazy(() =>
  import('@/pages/Demand/sub/ContactInformations'),
);
const ContactDetails = lazy(() => import('@/pages/Demand/sub/ContactDetails'));
const ContactDeclaration = lazy(() => import('@/pages/ContactDeclaration'));
const Errors = lazy(() => import('@/pages/Errors'));
const LabDeclaration = lazy(() => import('@/pages/LabDeclaration'));
const BatchSettingsStep = lazy(() =>
  import('@/pages/LabDeclaration/sub/BatchSettingsStep'),
);
const FormStep = lazy(() => import('@/pages/LabDeclaration/sub/FormStep'));
const FinalStep = lazy(() => import('@/pages/LabDeclaration/sub/FinalStep'));
const BioinfDeclaration = lazy(() => import('@/pages/BioinfDeclaration'));
const Faq = lazy(() => import('@/pages/Faq'));
const User = lazy(() => import('@/pages/User'));
const AdminComplementDemand = lazy(() =>
  import('@/pages/AdminComplementDemand'),
);
const MoreProjectInformations = lazy(() =>
  import('@/pages/Demand/sub/MoreProjectInformations'),
);

const BioItDashboard = lazy(() => import('@/pages/Dashboard/BioItDashboard'));

const userRoutes = (user) => (
  <>
    <Route index element={<UserDashboard user={user} />} />
    <Route path="projects" element={<ProjectsList />} user={user} />
    <Route
      path="demand/:demandId/declare-contacts"
      element={<ContactDeclaration />}
    />
    <Route path="demand/:demandId/declare-lab" element={<LabDeclaration />}>
      <Route path="batch-settings-step" element={<BatchSettingsStep />} />
      <Route path="form-step/:batchId" element={<FormStep />} />
      <Route path="final-step/:batchId" element={<FinalStep />} />
    </Route>
    <Route
      path="demand/:demandId/declare-bio-informatique"
      element={<BioinfDeclaration />}
    />
  </>
);

const adminRoutes = (user) => (
  <>
    <Route index element={<AdminDashboard user={user} />} />
    <Route
      path="demand/:demandId/complements"
      element={<AdminComplementDemand />}
    />
    <Route path="bioit/dashboard" element={<BioItDashboard />} />
    <Route path="users" element={<User />} />
  </>
);

const igRoutes = (user) => (
  <>
    <Route index element={<IgDashboard user={user} />} />
  </>
);

const commonRoute = (user) => (
  <Route path="demand/:demandId" element={<Demand />}>
    <Route path="batch" element={<SamplesInformations />}>
      <Route path=":batchId" element={<SamplesDetails />} />
    </Route>
    <Route path="contacts" element={<ContactInformations />}>
      <Route path=":contactId" element={<ContactDetails />} />
    </Route>
    {user.role !== 'user' && (
      <Route
        path="more-project-informations"
        element={<MoreProjectInformations />}
      />
    )}
  </Route>
);

const logisticRoutes = (user) => (
  <>
    <Route index element={<LogisticDashboard user={user} />} />
    <Route path="demand/:demandId" element={<Demand />} />
  </>
);

const publicRoutes = () => (
  <Route element={<LoginLayout />}>
    <Route path="/login" element={<Login />} />
    <Route path="/forgotten-password" element={<ForgottenPassword />} />
    <Route path="/reset-password" element={<RenewPassword />} />
  </Route>
);

const privateRoutes = (user) => (
  <Route element={<RequireAuth />}>
    <Route path="/" element={<LoginLayout />}>
      <Route path="change-password" element={<RenewPassword />} />
    </Route>
    <Route path="/" element={<Layout />}>
      <Route path="*" element={<Errors code="404" />} />
      {(user.role === 'admin' ||
        user.role === 'super_admin' ||
        user.role === 'bioinf') &&
        adminRoutes(user)}
      {user.role === 'ig' && igRoutes(user)}
      {user.role === 'user' && userRoutes(user)}
      {user.role === 'agent' && logisticRoutes(user)}
      {commonRoute(user)}
      <Route path="faq" element={<Faq />} />
    </Route>
  </Route>
);

const AllRoutes = () => (
  <UserContext.Consumer>
    {({ user }) => (
      <Routes>
        {publicRoutes()}
        {privateRoutes(user)}
      </Routes>
    )}
  </UserContext.Consumer>
);
export default AllRoutes;
