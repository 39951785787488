import { createContext } from 'react';

export const UserContext = createContext({
  user: {},
  setUser: () => {},
});

/* const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState({})

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  )
}

const useUserContext = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUserContext must be used within a UserContextProvider')
  }

  return context;
}

export { useUserContext, UserContextProvider }; */

// Zustand version
/* import create from 'zustand';

export const UserStore = create(set => ({
  user: null,
  changeUser: () => set((state) => {
    return { ...state, user: state.user }
  })
})) */
