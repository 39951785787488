import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { getAccessToken } from '@/utils/access_token';

const RequireAuth = () => {
  const token = getAccessToken();
  const { pathname } = useLocation();

  const redirectTo = pathname !== '/' ? `?redirect-to=${pathname}` : '';

  return (
    <>
      {token ? <Outlet /> : <Navigate to={`/login${redirectTo}`} replace />}
    </>
  );
};

export default RequireAuth;