import { BugReportRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import JiraForm from './JiraForm';

const JiraDialog = ({ setJiraDeclaration, open }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="lg"
      onClose={() => setJiraDeclaration(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              border: '1px solid',
              borderColor: 'info.main',
              borderRadius: '100%',
              p: 1,
            }}
          >
            <BugReportRounded sx={{ fontSize: 60 }} color="info" />
          </Box>
          <Typography variant="h5">{t('jira.some_trouble')}</Typography>
          <Typography variant="body1">
            <em>{t('jira.disclaimer')}</em>
          </Typography>
          <Divider flexItem />
          <Grid container alignItems="flex-start">
            <Grid
              item
              sm={12}
              md={8}
              pr={2}
              sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
            >
              <JiraForm
                setJiraDeclaration={setJiraDeclaration}
                setIsLoading={setIsLoading}
              />
            </Grid>
            <Grid
              item
              sm={12}
              md={4}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                border: 1,
                borderColor: 'info.main',
                borderRadius: 1,
              }}
            >
              <List sx={{ fontSize: 14 }}>
                <ListItem>{t('jira.helping_notes_1')}</ListItem>
                <ListItem>
                  {t('jira.helping_notes_2.title')}
                  <br />
                  {t('jira.helping_notes_2.bulletpoint1')}
                  <br />
                  {t('jira.helping_notes_2.bulletpoint2')}
                  <br />
                  {t('jira.helping_notes_2.bulletpoint3')}
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setJiraDeclaration(false)}
          color="secondary"
          variant="outlined"
        >
          {t('common.cancel')}
        </Button>
        <LoadingButton
          form="jira-form"
          type="submit"
          color="primary"
          variant="contained"
          autoFocus
          loading={isLoading}
        >
          {t('common.record')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

JiraDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setJiraDeclaration: PropTypes.func.isRequired,
};

export default JiraDialog;
