import 'dayjs/locale/fr';
import 'dayjs/locale/en';

import { NotificationsRounded } from '@mui/icons-material';
import { Badge, Box, IconButton, Menu } from '@mui/material';
import { useContext, useState } from 'react';

import { useNotifications } from '@/api';
import { UserContext } from '@/contexts';

import NotificationContent from './NotificationContent';
import NotificationHeader from './NotificationHeader';

const Notification = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useContext(UserContext);
  const { data: notifications } = useNotifications(user.id);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleClick} id="button-notifications">
        <Badge
          color="error"
          variant="dot"
          invisible={
            notifications?.items.filter((n) => n.seen === false).length === 0
          }
        >
          <NotificationsRounded
            sx={{ color: open ? 'primary.main' : 'default.main' }}
          />
        </Badge>
      </IconButton>
      <Menu
        id="basic-menu-usermenu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `button-notifications`,
          sx: {
            py: 0,
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          mt: 1.5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            width: { xs: 375, sm: 400, md: 450 },
            pt: 2,
          }}
        >
          <NotificationHeader />
          <NotificationContent notifications={notifications?.items} />
        </Box>
      </Menu>
    </div>
  );
};

export default Notification;
