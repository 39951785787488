import '@fontsource/lato';

import { createTheme } from '@mui/material';
import { enUS, frFR } from '@mui/material/locale';

const locales = {
  fr: frFR,
  en: enUS,
};

const generateTheme = (mode, locale) => {
  const colors = {
    light: {
      teal: '#3dcbb1',
      lightteal: '#63d5c0',
      darkteal: '#2a8e7b',
      grey: '#bdbdbd',
    },
    dark: {
      teal: '#63d5c0',
      lightteal: '#82ddcc',
      darkteal: '#459586',
      grey: '#bdbdbd',
    },
    white: '#fff',
    black: '#102437',
  };
  const theme = {
    breakpoints: {
      keys: ['xs', 'sm', 'md', 'lg', 'xl'],
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    palette: {
      mode,
      ...(mode === 'light'
        ? {
            primary: {
              light: colors.light.lightteal,
              main: colors.light.teal,
              dark: colors.light.darkteal,
              contrastText: colors.black,
            },
            secondary: {
              main: colors.black,
              contrastText: colors.white,
            },
            text: {
              primary: '#151515',
              disabled: 'rgba(0, 0, 0, 0.6)',
            },
            action: {
              disabled: 'rgba(0, 0, 0, 0.6)',
              disabledBackground: 'rgba(0, 0, 0, 0.48)',
            },
            error: {
              main: '#ef4444',
              constrastText: colors.white,
            },
            info: {
              main: '#467efe',
              constrastText: colors.white,
            },
            success: {
              main: '#059669',
              constrastText: colors.white,
            },
            warning: {
              main: '#f58646',
              constrastText: colors.white,
            },
            background: {
              default: '#f2f2f2',
              paper: colors.white,
            },
          }
        : {
            primary: {
              light: colors.dark.lightteal,
              main: colors.dark.teal,
              dark: colors.dark.darkteal,
              contrastText: colors.black,
            },
            secondary: {
              main: colors.white,
              contrastText: colors.black,
            },
            error: {
              main: '#f56c6c',
              constrastText: colors.white,
            },
            info: {
              main: '#6b97fe',
              constrastText: colors.white,
            },
            success: {
              main: '#00bc89',
              constrastText: colors.white,
            },
            warning: {
              main: '#feb959',
              constrastText: colors.black,
            },
            background: {
              default: '#1a1e21',
              paper: '#202528',
            },
          }),
    },
    typography: {
      fontFamily: 'Lato, Arial',
    },
    components: {
      // MuiSkeleton: {
      //   styleOverrides: {
      //     root: {
      //       transform: 'none',
      //     },
      //   },
      // },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            scrollbarColor:
              mode === 'dark' ? '#f2F2F2 #616161' : '#616161 #f2F2F2',
            '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
              backgroundColor:
                mode === 'dark'
                  ? 'rgba(242, 242, 242, 0.3)'
                  : 'rgba(97, 97, 97, 0.3)',
              width: 8,
              height: 8,
            },
            '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
              backgroundColor: mode === 'dark' ? '#f2F2F2' : '#616161',
              minHeight: 24,
            },
            '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus':
              {
                backgroundColor: mode === 'dark' ? '#C9C9C9' : '#454545',
              },
            '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active':
              {
                backgroundColor: mode === 'dark' ? '#C9C9C9' : '#454545',
              },
            '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover':
              {
                backgroundColor: mode === 'dark' ? '#C9C9C9' : '#454545',
              },
            '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
              backgroundColor: mode === 'dark' ? '#616161' : '#6b6b6b',
            },
          },
        },
      },
    },
  };
  return createTheme(theme, locales[locale]);
};

export default generateTheme;
