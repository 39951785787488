import './AppLoader.css';

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import Loader from '@/components/Loader';

const AppLoader = ({ delay }) => {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(true);
    }, delay);

    return () => clearTimeout(timeout);
  }, []);

  return visible ? (
    <div className="loader">
      <Loader fromApp />
      <div className="dna-brand">Corellia</div>
    </div>
  ) : null;
};

AppLoader.propTypes = {
  delay: PropTypes.number,
};

AppLoader.defaultProps = {
  delay: 250,
};

export default AppLoader;
