import {
  CheckRounded,
  ExpandLess,
  ExpandMore,
  LanguageRounded,
} from '@mui/icons-material';
import {
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import EnFlag from '@/assets/images/flags/en.png';
import FrFlag from '@/assets/images/flags/fr.png';
import queryClient from '@/utils/react-query';

const LanguageDropdown = () => {
  const { t, i18n } = useTranslation(['translation', 'common']);
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    queryClient.invalidateQueries('csv');
  };
  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <LanguageRounded />
        </ListItemIcon>
        <ListItemText primary={t('common.languages')} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => handleChangeLanguage('fr')}
          >
            <ListItemIcon>
              <img src={FrFlag} alt="French icon" />
            </ListItemIcon>
            <ListItemText>{t('french', { ns: 'common' })}</ListItemText>
            <Typography variant="body2" sx={{ ml: 1 }}>
              {i18n.language.includes('fr') && <CheckRounded />}
            </Typography>
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => handleChangeLanguage('en')}
          >
            <ListItemIcon>
              <img src={EnFlag} alt="English icon" />
            </ListItemIcon>
            <ListItemText>{t('english', { ns: 'common' })}</ListItemText>
            <Typography variant="body2" sx={{ ml: 1 }}>
              {i18n.language.includes('en') && <CheckRounded />}
            </Typography>
          </ListItemButton>
        </List>
      </Collapse>
    </>
  );
};

const LanguageMenu = () => {
  const { t, i18n } = useTranslation(['translation', 'common']);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    handleClose();
  };

  return (
    <div>
      <IconButton
        id="basic-button-translate"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          border: 1,
          borderStyle: 'solid',
          borderColor: 'secondary.main',
        }}
      >
        <LanguageRounded sx={{ color: 'text.primary' }} />
      </IconButton>
      <Menu
        id="basic-menu-translate"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `basic-button-translate`,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => handleChangeLanguage('fr')}>
          <ListItemIcon>
            <img src={FrFlag} alt="French icon" />
          </ListItemIcon>
          <ListItemText>{t('french', { ns: 'common' })}</ListItemText>
          <Typography variant="body2" sx={{ ml: 1 }}>
            {i18n.language.includes('fr') && <CheckRounded />}
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => handleChangeLanguage('en')}>
          <ListItemIcon>
            <img src={EnFlag} alt="English icon" />
          </ListItemIcon>
          <ListItemText>{t('english', { ns: 'common' })}</ListItemText>
          <Typography variant="body2" sx={{ ml: 1 }}>
            {i18n.language.includes('en') && <CheckRounded />}
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};

const LanguageSwitcher = ({ fromNav }) =>
  fromNav ? <LanguageDropdown /> : <LanguageMenu />;

LanguageSwitcher.propTypes = {
  fromNav: PropTypes.bool,
};

LanguageSwitcher.defaultProps = {
  fromNav: false,
};

export default LanguageSwitcher;
