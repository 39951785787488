import {
  Avatar,
  Box,
  Chip,
  Container,
  Grid,
  Link,
  Paper,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useGetWatermark } from '@/api';
import lightBrand from '@/assets/images/brand/Logo_IntegraGen_Group_CY.png';
import darkBrand from '@/assets/images/brand/Logo_IntegraGen_Group_CY_White.png';
import ThemeStore from '@/theme/ThemeStore';

const Footer = () => {
  const { t } = useTranslation();
  const themeSelected = ThemeStore((state) => state.theme);
  const { data: api, isLoading: isLoadingWatermark } = useGetWatermark();

  return (
    <Paper
      elevation={0}
      sx={{
        position: 'absolute',
        bottom: 0,
        p: 1,
        width: '100%',
        borderTop: '1px solid',
        borderColor: 'divider',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      square
    >
      <Container
        maxWidth={false}
        sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={4}
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          >
            <Box sx={{ height: 40, mr: 2 }}>
              <img
                src={themeSelected === 'light' ? lightBrand : darkBrand}
                alt="Brand"
                style={{ height: '100%' }}
              />
            </Box>
            {process.env.REACT_APP_ENVIRONMENT !== 'prod' && (
              <>
                <Link href="/" underline="none">
                  <Chip
                    avatar={<Avatar>App</Avatar>}
                    color="primary"
                    label={`${process.env.REACT_APP_VERSION}@${process.env.REACT_APP_ENVIRONMENT}`}
                    variant="outlined"
                    size="small"
                    clickable
                  />
                </Link>
                {!isLoadingWatermark && (
                  <Link
                    href={`${process.env.REACT_APP_API_URL}/docs`}
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="none"
                  >
                    <Chip
                      avatar={<Avatar>Api</Avatar>}
                      color="primary"
                      label={`${api.version}@${api.env}`}
                      variant="outlined"
                      size="small"
                      clickable
                    />
                  </Link>
                )}
              </>
            )}
          </Grid>
          {process.env.REACT_APP_ENVIRONMENT !== 'prod' && (
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant="h6"
                color="error.main"
                sx={{
                  justifySelf: 'center',
                  textTransform: 'uppercase',
                }}
              >
                {t('dev_env_footer', {
                  mode: process.env.REACT_APP_ENVIRONMENT,
                })}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Container>
    </Paper>
  );
};

export default Footer;
