import { useQuery } from 'react-query';

import axios from '@/utils/axios';

export const getTemplateUpload = async (id, isBioinf, url, demandId) => {
  let uri = url;
  if (id !== null) {
    uri += `&batch_id=${id}`;
  }

  if (demandId) {
    uri += `&demand_id=${demandId}`;
  }

  const { data } = await axios.get(uri, {
    responseType: 'blob',
  });
  return data;
};

export const getQuotePDF = async (quote) => {
  try {
    const { data } = await axios.get(`/salesforce/pdf_files?quote=${quote}`, {
      responseType: 'blob',
    });
    return data;
  } catch (error) {
    return null;
  }
};

export const getExportSamplesCsv = async (demandId) => {
  const { data } = await axios.get(`/samples/export/${demandId}`, {
    responseType: 'blob',
  });
  return data;
};

export const getExportComplementsCsv = async (numFc = '', clientID = '', igID = '', poolIgName = '', projectName = '') => {
  let url = `/fastqc/complements/?`;
  if (numFc !== '')
    url += `num_run=${numFc}&`;
  if (clientID !== '')
    url += `client_id=${clientID}&`;
  if (igID !== '')
    url += `ig_id=${igID}&`;
  if (poolIgName !== '')
    url += `pool_ig_name=${poolIgName}&`;
  if (projectName !== '')
    url += `project_name=${projectName}&`;
  url = url.slice(0, -1);
  const { data } = await axios.get(url, {responseType: 'blob'});
  return data;
};

export const useTemplateUpload = ({
  id = null,
  isBioinf = false,
  url,
  demandId = null,
  queryProps = {},
}) =>
  useQuery(
    ['csv', [id, isBioinf, url, demandId]],
    () => getTemplateUpload(id, isBioinf, url, demandId),
    queryProps,
  );

export const useQuotePDF = (quote) =>
  useQuery(['quote', quote], () => getQuotePDF(quote));

export const useExportSamplesCsv = (demandId) =>
  useQuery(['export_samples', demandId], () => getExportSamplesCsv(demandId));

export const useExportComplementsCsv = (numFc, clientID, igID, poolIgName, projectName, options) =>
  useQuery(
    ['fastqc_export_complements', [numFc, clientID, igID, poolIgName, projectName]],
    () => getExportComplementsCsv(numFc, clientID, igID, poolIgName, projectName),
    options
  );
