import { Box, Button, Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useSeenAllNotifications } from '@/api';
import { UserContext } from '@/contexts';

const NotificationHeader = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { mutate: seenAllNotifications } = useSeenAllNotifications();
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2 }}>
      <Typography variant="h5">Notifications</Typography>
      <Button
        variant="text"
        color="primary"
        sx={{ textTransform: 'none' }}
        onClick={() => seenAllNotifications(user.id)}
      >
        {t('notification.mark_all_as_read')}
      </Button>
    </Box>
  );
};

export default NotificationHeader;
