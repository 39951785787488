import { omit } from 'lodash';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import axios from '@/utils/axios';

export const getMe = async () => {
  const { data } = await axios.get('/users/me/');
  return data;
};

export const updateMe = async ({ id, values }) => {
  const { data } = await axios.put(`/users/${id}`, values);
  return data;
};

export const getAllUsers = async () => {
  const { data } = await axios.get('/users/');
  return data;
};

export const getMonitor = async () => {
  const { data } = await axios.get('/internal/monitor/');
  return data;
};

export const needPasswordReset = async (values) => {
  const { data } = await axios.post(
    `/password/email/`,
    new URLSearchParams(values),
  );
  return data;
};

export const needPasswordChange = async (values, user) => {
  const { data } = await axios.put(`/users/${user.id}`, {
    hashed_password: values.password,
  });
  return data;
};

export const needLogout = async () => {
  const { data } = await axios.delete('/logout/');
  return data;
};

export const createUser = async (newUser) => {
  const { data } = await axios.post('/users/', newUser);
  return data;
};

export const updateUser = async (user) => {
  const payload = omit(user.user, ['id', 'username']);
  payload.refresh_token = null;
  const { data } = await axios.put(`/users/${user.id}`, payload);
  return data;
};

export const useMe = () => useQuery(['me'], getMe);

export const useAllUsers = () => useQuery(['allUsers'], getAllUsers);
export const useMonitor = () => useQuery(['monitor'], getMonitor);

export const useCreateUser = () => {
  const queryClient = useQueryClient();

  return useMutation((newUser) => createUser(newUser), {
    onMutate: async (newUser) => {
      await queryClient.cancelQueries(['allUsers']);

      const prevUsers = queryClient.getQueryData(['allUsers']);

      queryClient.setQueryData(
        ['allUsers'],
        [...prevUsers, { ...newUser, enabled: true }],
      );

      return { prevUsers };
    },
    onError: (_, __, { prevUsers }) => {
      queryClient.setQueryData(prevUsers);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['allUsers']);
    },
  });
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();

  return useMutation((user) => updateUser(user), {
    onMutate: async (user) => {
      await queryClient.cancelQueries(['allUsers']);

      const prevUsers = queryClient.getQueryData(['allUsers']);

      const newPrevUsers = prevUsers.map((u) => {
        if (u.id === user.id) {
          return { ...u, enabled: user.user.enabled };
        }
        return u;
      });

      queryClient.setQueryData(['allUsers'], newPrevUsers);

      return { prevUsers };
    },
    onError: (_, __, { prevUsers }) => {
      queryClient.setQueryData(prevUsers);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['allUsers']);
    },
  });
};

export const useUpdateMe = () => {
  const queryClient = useQueryClient();

  return useMutation(updateMe, {
    onSettled: () => {
      queryClient.invalidateQueries(['me']);
    },
  });
};

export const getWatermark = async () => {
  const { data } = await axios.get(`/watermark/`);
  return data;
};

export const useGetWatermark = () =>
  useQuery(['watermark'], getWatermark, {
    refetchOnWindowFocus: false,
  });
