import { Box, CircularProgress, Paper } from '@mui/material';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import bg from '@/assets/images/bg/bg_login_w_blob.png';
import darkBrand from '@/assets/images/brand/corellia_dark.png';
import lightBrand from '@/assets/images/brand/corellia_light.png';
import lightMonogram from '@/assets/images/brand/Monogramme_G.png';
import darkMonogram from '@/assets/images/brand/Monogramme_G_White.png';
import Loader from '@/components/Loader';
import ThemeStore from '@/theme/ThemeStore';

import LanguageDropdown from '../Header/components/LanguageDropdown';

const LoginLayout = () => {
  const themeSelected = ThemeStore((state) => state.theme);
  const year = new Date().getFullYear();
  return (
    <Box sx={{ display: 'flex' }}>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          px: { xs: '20px', sm: '100px' },
          pt: '34px',
          height: '100vh',
          justifyContent: 'center',
          width: { xs: '100%', sm: '100%', md: 'auto' },
          minWidth: { xs: 0, sm: 0, md: '672px' },
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ width: 280, display: 'flex', alignItems: 'center' }}>
            <img
              src={themeSelected === 'light' ? lightBrand : darkBrand}
              alt="Corellia"
              width="100%"
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            <Suspense fallback={<CircularProgress />}>
              <LanguageDropdown />
            </Suspense>
          </Box>
        </Box>
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
        <Box
          mt={1}
          mb={1}
          sx={{
            color: 'rgb(178, 186, 194)',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                width: themeSelected === 'light' ? 48 : 40,
                height: themeSelected === 'light' ? 48 : 40,
                p: themeSelected === 'light' ? 0 : 1,
              }}
            >
              <img
                src={themeSelected === 'light' ? lightMonogram : darkMonogram}
                alt="Corellia"
                width="70%"
              />
            </Box>
            &nbsp;© IntegraGen 2023-{year} - All Rights Reserved
          </Box>
        </Box>
      </Paper>
      <Box
        item
        sm={12}
        md={8}
        sx={{
          display: { xs: 'none', sm: 'none', md: 'flex' },
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          position: 'relative',
          flexGrow: 1,
          overflow: 'hidden',
        }}
      >
        <img
          src={bg}
          alt="Login"
          style={{ zIndex: 12, minWidth: '350px', width: '40%' }}
        />
      </Box>
    </Box>
  );
};

export default LoginLayout;
