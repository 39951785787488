import { useMutation, useQuery, useQueryClient } from 'react-query';

import axios from '@/utils/axios';

export const getAllContacts = async () => {
  const { data } = await axios.get('/contacts/');
  return data;
};

export const getAllContactsByDemandId = async (id) => {
  const { data } = await axios.get(`/contacts/demands/?demand_id=${id}`);
  return data;
};

export const getContact = async (id) => {
  const { data } = await axios.get(`/contacts/${id}`);
  return data;
};

export const postContact = async ({ values }) => {
  const { data } = await axios.post(`/contacts/`, values);
  return data;
};

export const postSfContact = async ({ values, demandId }) => {
  const { data } = await axios.post(
    `/salesforce/new-contact-in-sf?demand_id=${demandId}`,
    values,
  );
  return data;
};

export const putContact = async ({ values }) => {
  const { data } = await axios.put(`/contacts/${values.id}`, values);
  return data;
};

export const editSfContact = async ({ values, contactSfId }) => {
  const { data } = await axios.post(
    `/salesforce/update-sf-contact?contact_sf_id=${contactSfId}`,
    values,
  );
  return data;
};

export const deleteContact = async ({ id }) => {
  const { data } = await axios.delete(`/contacts/${id}`);
  return data;
};

export const postContactDemand = async (values) => {
  const { data } = await axios.post(`/contacts/demands/`, values);
  return data;
};

export const putContactDemand = async (values) => {
  const { data } = await axios.put(
    `/contacts/demands/${values.contact_id}/${values.demand_id}`,
    values,
  );
  return data;
};

export const deleteContactDemand = async ({ id, demandId }) => {
  const { data } = await axios.delete(`/contacts/demands/${id}/${demandId}`);
  return data;
};

export const getRelatedContacts = async (demandId) => {
  const { data } = await axios.get(`/contacts/my/?demand_id=${demandId}`);
  return data;
};

export const useAddContact = () => {
  const queryClient = useQueryClient();

  return useMutation(postContact, {
    onSettled: () => {
      queryClient.invalidateQueries(['demands']);
      queryClient.invalidateQueries(['contact', 'related']);
    },
  });
};

export const useAddInitialContact = () => {
  const queryClient = useQueryClient();

  return useMutation(postSfContact, {
    onSettled: () => {
      queryClient.invalidateQueries(['demands']);
      queryClient.invalidateQueries(['contact', 'related']);
    },
  });
};

export const useEditContact = () => {
  const queryClient = useQueryClient();
  return useMutation(putContact, {
    onSettled: () => {
      queryClient.invalidateQueries(['demands']);
      queryClient.invalidateQueries(['contact', 'related']);
    },
  });
};

export const useEditInitialContact = () => {
  const queryClient = useQueryClient();
  return useMutation(editSfContact, {
    onSettled: () => {
      queryClient.invalidateQueries(['demands']);
      queryClient.invalidateQueries(['contact', 'related']);
    },
  });
};

export const useDeleteContact = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteContactDemand, {
    onSettled: () => {
      queryClient.invalidateQueries(['demands']);
      queryClient.invalidateQueries(['contact', 'related']);
    },
  });
};

export const useContacts = () => useQuery(['contacts'], getAllContacts);

export const useDemandContacts = (id) =>
  useQuery(['demand_contacts', id], () => getAllContactsByDemandId(id));

export const useContact = (id) =>
  useQuery(['contact', id], () => getContact(id));

export const useRelatedContacts = (demandId) =>
  useQuery(['contact', 'related', demandId], () =>
    getRelatedContacts(demandId),
  );
