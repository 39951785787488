import { useMutation, useQuery, useQueryClient } from 'react-query';

import axios from '@/utils/axios';

const normalizeFastQc = (data) => {
  const normalizeFQ = [];
  data.forEach((fastQc) => {
    const { sample_bioit: sb, flowcell, ...fq } = fastQc;
    const { sample, ...sbr } = sb;

    const isDegraded = sample === null;
    const pjName = isDegraded
      ? sbr.pj_name
      : `${sample.sample_batch.demand.name}${
          sample.sample_batch.demand.name_extension !== null
            ? `-${sample.sample_batch.demand.name_extension}`
            : ''
        }`;
    const poolId = isDegraded ? sbr.pool_id : sample.name;
    const totalSample = fq.total_sample;
    const runTaille = isDegraded
      ? sbr.project_seq_length
      : sample.sample_batch.demand.seq_length;
    const expedtedCls = isDegraded
      ? Number(sbr.seq_size_qty)
      : Number(fq.x_clusters);
    const clsDevis = totalSample - expedtedCls * 1000000;
    const ssq = isDegraded
      ? null
      : JSON.parse(sample?.sample_batch.demand.seq_sizes_qte).data.filter(
          (d) => Number(d.size) === expedtedCls,
        );

    const margin = ssq && ssq.length > 0 ? ssq[0].margin : 15;
    const labType = isDegraded
      ? null
      : sample.sample_batch.demand.lab_type.label;
    const expedtedMarged = expedtedCls * 1000000 * (1 - margin * 0.01);
    normalizeFQ.push({
      id: fq.id,
      id_sample_bioit: sbr.id,
      is_degraded: isDegraded,
      flowcell: flowcell.label,
      id_ig: sbr.ig_id,
      id_client: sbr.client_id,
      pj_name: pjName,
      pool_id: poolId,
      cf_qpcr: fq.cf_qpcr?.toFixed(2),
      pfc: fq.pfc,
      run_taille: runTaille,
      q30: fq.q30,
      q_score: fq.quality_score,
      expected_clusters: expedtedCls * 1000000,
      clusters: fq.clusters,
      seq_status: fq.status,
      sample_total: totalSample,
      pool_total: fq.total_pool,
      clusters_devis: clsDevis,
      cluster_devis_marge: totalSample - expedtedMarged,
      sb_status: sbr.status,
      is_pool: fq.is_pool,
      is_lane: fq.is_lane,
      lab_type: labType,
      poolig_name: sbr.poolig_name,
    });
  });

  return normalizeFQ;
};

export const getAllFastQc = async (complementOnly, params) => {
  let url = complementOnly
    ? '/fastqc?is_dmx=true&sample_status=complement&sample_status=ok_complement'
    : '/fastqc?is_dmx=true';
  if (params) {
    Object.keys(params).forEach((key) => {
      url += `&${key}=${params[key]}`;
    });
  }
  const { data } = await axios.get(url);
  return data;
};

export const getAllFastQcPool = async (pj, poolId) => {
  const { data } = await axios.get(
    `/fastqc/pools/?pj=${pj.split('-')[0]}&pool=${poolId}`,
  );
  return data;
};

export const putFastQc = async ({ values, fastQcId }) => {
  const { data } = await axios.put(`/fastqc/${fastQcId}`, values);
  return data;
};

export const useFastQc = (complementOnly, params) => {
  const queryClient = useQueryClient();
  return useQuery(
    ['fastqc', complementOnly, params],
    () => getAllFastQc(complementOnly, params),
    {
      enabled:
        (params && Object.keys(params).length !== 0) ||
        complementOnly !== false,
      select: (data) => normalizeFastQc(data),
      refetchOnWindowFocus: false,
      onSettled: () => {queryClient.invalidateQueries(['fastqc_export_complements']);},
    },
  );
};

export const useFastQcPool = ({ pj, poolId }) =>
  useQuery(['fastqc_pool', pj, poolId], () => getAllFastQcPool(pj, poolId), {
    select: (data) => normalizeFastQc(data),
    refetchOnWindowFocus: false,
  });

export const useUpdateFastQc = () => {
  const queryClient = useQueryClient();

  return useMutation(putFastQc, {
    onSettled: () => {
      queryClient.invalidateQueries(['fastqc']);
      queryClient.invalidateQueries(['fastqc_pool']);
      queryClient.invalidateQueries(['fastqc_export_complements']);
    },
  });
};
