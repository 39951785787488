import { useMutation, useQuery, useQueryClient } from 'react-query';

import axios from '@/utils/axios';

const formatProperties = (sample) => {
  const properties = {};
  sample.properties.forEach((prop) => {
    properties[prop.sample_property.label] = {
      value: prop.value,
      extra: prop.extra,
    };
  });
  return properties;
};

export const createBatch = async (
  demandId,
  conditionning,
  content,
  selectedProtocol,
  protocolName,
) => {
  const { data: batch } = await axios.post('/samples/batches/', {
    demand_id: demandId,
    status: 1,
    conditioning_id: conditionning,
    content_id: content,
    protocol_id: selectedProtocol,
    created_at: new Date(),
    protocol_label: protocolName,
  });

  return batch;
};

export const getBatch = async (id) => {
  const { data } = await axios.get(`/samples/batches/${id}?include=samples`);

  data.samples = data.samples.map((sample) => ({
    ...sample,
    ...formatProperties(sample),
  }));

  data.samples.forEach((_, index) => {
    delete data.samples[index].properties;
  });

  data.demand.seq_sizes_qte = JSON.parse(data.demand.seq_sizes_qte);
  return data;
};

export const updateBatchFormat = async ({
  batchId,
  conditionning,
  content,
  selectedProtocol,
  protocolName,
}) => {
  await axios.put(`/samples/batches/${batchId}`, {
    conditioning_id: conditionning,
    content_id: content,
    protocol_id: selectedProtocol,
    protocol_label: protocolName,
  });

  return null;
};

export const updateStatusDemand = async ({ batchId, batchStatus }) => {
  await axios.put(`/samples/batches/${batchId}`, {
    status_id: batchStatus,
  });
  return null;
};

export const updateBatchStatusAndDemandReturn = async ({
  demandId,
  batchId,
  batchStatus,
  contactReturn,
  needReturn,
}) => {
  if (needReturn) {
    await axios
      .post(`/demands/returns/${demandId}`, contactReturn)
      .then(async () => {
        updateStatusDemand({
          batchId,
          batchStatus,
        });
      });
    return null;
  }
  await updateStatusDemand({
    batchId,
    batchStatus,
  });
  return null;
};

export const useBatch = (id, options = {}) =>
  useQuery(['batch', id], () => getBatch(id), options);

export const useValidBatchWithDemandReturn = () => {
  const queryClient = useQueryClient();

  return useMutation((datas) => updateBatchStatusAndDemandReturn(datas), {
    onMutate: async ({ batchId, batchStatus }) => {
      await queryClient.cancelQueries(['samplesDemand', batchId]);
      const prevBatch = queryClient.getQueryData(['samplesDemand', batchId]);
      queryClient.setQueryData(['samplesDemand', batchId], {
        ...prevBatch,
        batch: batchStatus,
      });
      return { prevBatch };
    },
    onError: (_, newBatch, { prevBatch }) => {
      queryClient.setQueryData(['samplesDemand', newBatch.batchId], prevBatch);
    },
    onSettled: (_, __, newBatch) => {
      queryClient.invalidateQueries(['samplesDemand', newBatch.batchId]);
      queryClient.invalidateQueries(['demands']);
    },
  });
};

export const useUpdateBatchFormat = () => {
  const queryClient = useQueryClient();

  return useMutation((datas) => updateBatchFormat(datas), {
    onSettled: () => {
      queryClient.invalidateQueries(['samplesDemand']);
      queryClient.invalidateQueries(['demands']);
      queryClient.invalidateQueries(['batch']);
    },
  });
};

export const getConditionnings = async () => {
  // const { data } = await axios.get('/contacts/');
  const data = [
    {
      id: 1,
      label: 'plates',
    },
    {
      id: 2,
      label: 'tubes',
    },
  ];
  return data;
};

export const useConditionnings = () =>
  useQuery('conditionnings', () => getConditionnings());

export const getContents = async () => {
  // const { data } = await axios.get('/contacts/');
  const data = [
    {
      id: 1,
      label: 'pool',
    },
    {
      id: 2,
      label: 'library',
    },
  ];
  return data;
};

export const useContents = () => useQuery('contents', () => getContents());
